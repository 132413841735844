/* Product Tile Markup - You can play around with this to change the
information that appears on the Wishlisted Product tiles
https://swym.it/help/how-to-render-a-custom-wishlist-on-an-existing-page/
*/
var productCardMarkup = `<div class="product-grid-container scroll-trigger animate--slide-in" data-cascade>
  <div class="swym-wishlist-grid product-grid relative !grid grid-cols-2 grid-flow-row gap-2 lg:grid-cols-4 lg:gap-3">
    {{#products}}
    <a
      href="{{du}}"aria-label="{{dt}}"
      class="swym-wishlist-item swym-is-anchor relative group color-scheme-4 h-full scroll-trigger animate--slide-in"
      data-cascade
      style="--animation-order: {{ idx }};">
      <button id="swym-remove-productBtn" aria-label="Delete" data-variant-id="{{epi}}" data-product-id="{{empi}}" class="swym-delete-btn swym-nav swym-nav-1 swym-is-button">
        <svg aria-hidden="true" focusable="false" fill="none" width="16" class="{{ class | strip }}" viewBox="0 0 16 16">
          <path d="m1 1 14 14M1 15 15 1" stroke="currentColor" stroke-width="{{ stroke_width | default: settings.icon_stroke_width }}"/>
        </svg>
      </button>
      <div class="swym-wishlist-image-wrapper">
        <img alt="" class="swym-wishlist-image" src="{{iu}}" loading="lazy">
      </div>
        <div class="flex flex-row justify-between font-sans text-xxs p-5">
          <div>
            <h3 class="font-sans text-xxs">{{dt}}</h3>
            <p>by {{bt}}</p>
          </div>
          <p>{{cu}}{{pr}}</p>
        </div>
    </a>
    {{/products}}
  </div>
</div>
{{^products}}
	<div class="container px-8 pt-6 mx-auto text-center lg:px-0 lg:pt-12 lg:pb-16 pb-7">
  	<div class="pt-40 pb-60 block w-full mx-auto rte" id="bc-sf-filter-message">Your Wishlist is empty.</div>
	</div>
{{/products}}`;

function onRemoveBtnClick(e) {
  e.preventDefault();
  var epi = +e.currentTarget.getAttribute('data-variant-id');
  var empi = +e.currentTarget.getAttribute('data-product-id');
  window._swat.fetch(function (products) {
    products.forEach(function (product) {
      if (epi && empi && product.epi == epi && product.empi == empi) {
        window._swat.removeFromWishList(product, function () {
          if (!window.SwymCallbacks) {
            window.SwymCallbacks = [];
          }
          window.SwymCallbacks.push(swymRenderWishlist);
        });
      }
    });
  });
}

function swymRenderWishlist(swat) {
  // Get wishlist items
  swat.fetch(function (products) {
    console.log('swymRenderWishlist', products);
    var wishlistContentsContainer = document.getElementById('wishlist-items-container');
    var formattedWishlistedProducts = products.map(function (p, idx) {
      p = SwymUtils.formatProductPrice(p); // formats product price and adds currency to product Object
      p.idx = idx; // index number to apply animation
      return p;
    });
    var productCardsMarkup = SwymUtils.renderTemplateString(productCardMarkup, {
      products: formattedWishlistedProducts
    });
    if (wishlistContentsContainer) {
      wishlistContentsContainer.innerHTML = productCardsMarkup;
      attachClickListeners();
    } else {
      console.log('Container not found, Wishlist Page element not found');
    }
  });
}

function attachClickListeners() {
  var removeBtns = document.querySelectorAll('#swym-remove-productBtn');
  //   Remove Buttons
  for (var k = 0; k < removeBtns.length; k++) {
    removeBtns[k].addEventListener('click', onRemoveBtnClick, false);
  }
  console.log('Events attached!');
}

if (!window.SwymCallbacks) {
  window.SwymCallbacks = [];
}
window.SwymCallbacks.push(swymRenderWishlist); /* Init Here */
